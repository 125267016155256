<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
                <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                :isGkkpPaid="true"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadRepPaid="downloadRepPaid"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header 
                    ref="budgetHeader" 
                    :form="form" 
                    :yearReq="true" 
                    :openDisabled="openDisabled" 
                    :currTabName="currTabName"
                    @setProgress="setProgress"
                    @chgData="changeHeader" 
                ></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="isPaidTab ?  budgetFormPaid : budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        :isPaidTab="isPaidTab"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="onEnterCategoryName" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <b-tabs 
            active-nav-item-class="budget-form_tabs_active_tab"
            nav-class="budget-form_tabs" 
            v-model="activeTabIndex"
        >

            <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                                :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
            <b-progress
                    variant="success"
                    v-show="progress < 100 && progress > 0"
                    height="10px"
                    :value="progress"
                    striped
                    animated
            ></b-progress>

            <b-tab 
                :title="getCommonText('budget_expense')"
                @click="updateRoute(0)" 
            >
                <!-- Первая вклапдка -->
                <form-01-169-tab1  
                    ref="formTab"
                    :budgetForm="budgetForm"
                    :getCommonText="getCommonText"
                    :getDecText="getDecText"
                    :getUnitsText="getUnitsText"
                    :setFormText="setFormText"
                    :getUnitLockName="getUnitLockName"
                    :lng="lng"
                    :variantAttribute="variantAttribute"
                    :nameIsEmpty="nameIsEmpty"
                    :listUnit="listUnit"
                    :total="total"
                    :isLoad="isLoad"
                    @setSelectAll="setSelectAll"
                    @deleteItemWithAttachedFiles="deleteItemWithAttachedFiles"
                    @onEditCatName="onEditCatName"
                    @keyup13="keyup13"
                    @keyPress="keyPress"
                    @inputFixedVldtn="inputFixedVldtn"
                    @onFilesClick="onFilesClick"
                    @onAddNewCat="onEnterCategoryName"
                ></form-01-169-tab1>
        
                <!-- Первая вклапдка -->
                <files-updown :header="header"
                            :variant-attribute="variantAttribute"
                            :load="load"
                            @getFiles="getFiles"
                            @getNewFiles="getNewFiles($event)"
                            @delFile="delFile($event)"
                            ref="fileUpdown"
                ></files-updown>

                <!-- Первая вклапдка -->
                <modal-files-management-nodecode
                    ref="modalFilesManagement"
                    :variant-attribute="variantAttribute"
                    @triggDownloadFile="triggDownloadFile"
                    @triggDownloadAllFiles="triggDownloadAllFiles"
                    @fileUpload="fileUpload"
                    @toggleIsAdd="toggleIsAdd($event)"
                />

                <!-- Первая вклапдка -->
                <c-set-b-f-category-name
                    ref="setCatNameModal"
                    :budgetForm="budgetForm"
                    :categoryFieldNames="categoryFieldNames"
                    :lng="lng"
                    :getCommonText="getCommonText"
                    @addItem="addItem"
                />
            </b-tab>
            <b-tab 
                :title="getCommonText('paid_services')" 
                @click="updateRoute(1)"
            >
                <!-- Вторая вклапдка -->
                <form-01-169-tab1  
                    ref="formTabPaid"
                    :budgetForm="budgetFormPaid"
                    :getCommonText="getCommonText"
                    :getDecText="getDecText"
                    :getUnitsText="getUnitsText"
                    :setFormText="setFormText"
                    :getUnitLockName="getUnitLockName"
                    :lng="lng"
                    :variantAttribute="variantAttribute"
                    :nameIsEmpty="nameIsEmpty"
                    :listUnit="listUnit"
                    :total="totalPaid"
                    :isLoad="isLoad"
                    @setSelectAll="setSelectAll"
                    @deleteItemWithAttachedFiles="deleteItemWithAttachedFilesPaid"
                    @onEditCatName="onEditCatNamePaid"
                    @selectedUnitChanged="selectedUnitChanged"
                    @keyup13="keyup13"
                    @keyPress="keyPress"
                    @inputFixedVldtn="inputFixedVldtn"
                    @onFilesClick="onFilesClickPaid"
                    @onAddNewCat="onEnterCategoryName"
                ></form-01-169-tab1>

                <!-- Вторая вклапдка -->
                <files-updown :header="header"
                            :variant-attribute="variantAttribute"
                            :load="load"
                            :isPaidTab="true"
                            @getFiles="getFilesPaid"
                            @getNewFiles="getNewFilesPaid($event)"
                            @delFile="delFilePaid($event)"
                            ref="fileUpdownPaid"
                ></files-updown>

                <!-- Вторая вклапдка -->
                <modal-files-management-nodecode
                    ref="modalFilesManagementPaid"
                    :variant-attribute="variantAttribute"
                    @triggDownloadFile="triggDownloadFilePaid"
                    @triggDownloadAllFiles="triggDownloadAllFilesPaid"
                    @fileUpload="fileUploadPaid"
                    @toggleIsAdd="toggleIsAddPaid($event)"
                />

                <!-- Вторая вклапдка -->
                <c-set-b-f-category-name
                    ref="setCatNameModalPaid"
                    :budgetForm="budgetFormPaid"
                    :categoryFieldNames="categoryFieldNames"
                    :lng="lng"
                    :getCommonText="getCommonText"
                    @addItem="addItem"
                />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1Paid from "./mixins/forms-handler-mixin-1-paid";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";
import Form01169Tab1 from './Form01_169_tab1.vue';

export default {
    name: 'Form01_169',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        CSetBFCategoryName,
        Form01169Tab1,
    },
    mixins: [FormsHandlerMixin1Paid],
    data() {
        return {
            form: {
                code: '01-169',
                name_ru: 'Расчет расходов по специфике "Прочие текущие затраты"',
                name_kk: '"Өзге де ағымдағы шығыстар" ерекшелiгi бойынша шығыстарды есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            budgetFormPaid: [],
            header: null,
            files: null,
            filesPaid: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            rowIdPaid: false,
            newRowStartId: -1,
            categoryFieldNames: {ru: "name_ru", kk: "name_kk"}
        };
    },
    async mounted() {
        await this.loadUnits();
    },

    watch: {
        selectAllPaid() {
            this.$refs.formTabPaid.setSelectAll(this.selectAllPaid);
        },

        selectAll() {
            this.$refs.formTab.setSelectAll(this.selectAll);
        }
    },

    methods: {
        addItem(nameRu, nameKk) {
            const item = { 
                id: this.newRowStartId,
                name_ru: nameRu,
                name_kk: nameKk
            };
            this.newRowStartId--;
            this.itemUpdate(item);
            this.defineCalcProps(item);
            if (this.activeTabIndex === 0) this.budgetForm.push(item);
            if (this.activeTabIndex === 1) this.budgetFormPaid.push(item);
        },

        defineCalcProps(item) {
             Object.defineProperty(item, 'total', {
                get: () => {
                    const amount = this.safeDecimal(item.amount);
                    const costAvg = this.safeDecimal(item.cost_avg);
                    const totalRounded = amount.mul(costAvg).toDecimalPlaces(2).toNumber();
                    return totalRounded;
                }
            });
        },

        inputFixed(item, field, value, digit, max = 0) {
            // item - строка
            // field - наименование поля
            // value - глвле значение
            // digit - количество цифр после запятой
            // max - максимальное значение
            this.inputFixedVldtn(item, field, value, digit);
            if ((max > 0) && (value > max)) {
                this.$set(item, field, 0);
                this.makeToast('danger', 'Предупреждение', 'Требуется ввести число <= ' + max);
            }
        }, // форматирует введенное значение до digit цифр после запятой

        async itemUpdate(item) {
            this.$set(item, 'unit', null);
            this.$set(item, 'unit_inf', null);
            this.$set(item, 'amount', 0);
            this.$set(item, 'cost_avg', 0);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'files', 0);

            try {
                this.load = true;
                let url = `/api-py/get-new-row-files-form/${item.id}/`;
                if (this.activeTabIndex === 1) url = `/api-py/get-new-row-files-form-paid/${item.id}/`;
                const response = await fetch(url + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        getFiles(data) {
            this.files = data;
        },
        getFilesPaid(data) {
            this.filesPaid = data;
        },

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                const values = await response.json();
                this.fillBudgetForm(values, this.budgetForm);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        },

        async loadDatasPaid() {
            this.load = true;
            await this.budgetFormPaid.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files-paid/' + JSON.stringify(this.header));
                const values = await response.json();
                this.fillBudgetForm(values, this.budgetFormPaid);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatasPaid`, error.toString());
            }
            this.load = false;
        },

        fillBudgetForm(values, budgetForm) {
            values.forEach(val => {
                const item = {
                    id: val.id,
                    name_ru: val.name_ru,
                    name_kk: val.name_kk,
                    unit: val.unit,
                    unit_inf: this.getItem(val.unit, this.listUnit),
                    amount: val.amount,
                    cost_avg: val.cost_avg,
                    files: val.files,
                    row_files: val.row_files,
                    itemToDelete: false,
                };
                this.defineCalcProps(item);

                budgetForm.push(item);
            });
        },

        prepareForSave() {
            let budgetForm = [];
            if (this.activeTabIndex === 1) budgetForm = this.budgetFormPaid
            else budgetForm = this.budgetForm;
            const values = [];
            for (const row of budgetForm) {
                if (this.isSavingForbidden(row, this.$refs.formTabPaid.tableFields, ['amount', 'cost_avg'], [])) return;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'name_ru', row.name_ru);
                this.$set(item, 'name_kk', row.name_kk);
                if (row.unit_inf) {
                    this.$set(item, 'unit', row.unit_inf.code);
                }
                else {
                    this.$set(item, 'unit', null);
                }
                this.$set(item, 'amount', parseInt(row.amount));
                this.$set(item, 'cost_avg', parseFloat(row.cost_avg));
                this.$set(item, 'row_files', row.row_files);

                values.push(item);
            }
            if (values.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    this.getCommonText('on_save'),
                    {
                        title: this.getCommonText('confirm'),
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: this.getCommonText('yes'),
                        cancelTitle: this.getCommonText('cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value && this.variantAttribute) {
                            if (this.activeTabIndex === 0) {
                                this.save(values) 
                            } else { 
                                this.savePaid(values);
                            }
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', this.getErrText('saving_error'), error.toString());
                    }
                );
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async savePaid(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.totalPaid);
                const url = '/api-py/save-brform-gkkp-paid/'
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.filesPaid.length === 0) {
                        this.makeToast('danger', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    await this.loadDatasPaid();
                } else {
                    await this.loadDatasPaid();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

    },
    computed: {
        total() {
            let sum = this.safeDecimal(0);
            for (const row of this.budgetForm) {
                const total = this.safeDecimal(row.total);
                sum = sum.add(total);
            }
            return Math.ceil(sum.toNumber());
        },
        totalPaid() {
            let sum =this.safeDecimal(0);
            for (const row of this.budgetFormPaid) {
                const total = this.safeDecimal(row.total);
                sum = sum.add(total);
            }
            return Math.ceil(sum.toNumber());
        },
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .budget-form_tabs {
        display: none;
    }
</style>