import { Ax } from '@/utils';
import formsHandlerMixin1 from './forms-handler-mixin-1';
//  ************************************************** //
//      Миксин для форм c вкладкой Платные Услуги      //
//  ************************************************** //
export default {
    mixins: [formsHandlerMixin1],
    mounted() {
        this.setTabsStyle({top: '177px'});
        window.addEventListener('hashchange', () => {
            this.setActiveTab();
        });
    },
    data() {
        return {
            activeTabIndex: this.getActiveTab(),
            budgetForm: [],
            selectAllPaid: false,
        }
    },

    watch: {
        isGuMode() {
            this.setTabsStyle()
        }
     }, 

    methods: {
        setTabsStyle(styles = null) {
            const tabs = this.$el.querySelector('.budget-form_tabs');
            if (tabs) {
                if (styles) {
                    tabs.style.top = styles.top;
                }
                if (this.isGuMode === false) {
                    tabs.style.display = 'flex';
                } else {
                    tabs.style.display = 'none';
                }
            }
        },

        setActiveTab() {
            this.activeTabIndex = this.getActiveTab();
        },

        getActiveTab() {
            const tabMap = {
              'budg': 0,
              'paid': 1,
            };
            return tabMap[this.$route.name] || 0;
        },

        updateRoute(index) {
            const routes = ['budg', 'paid'];
            let currentURL = window.location.href;
            let newURL = currentURL;
            const isUrlHasTabCode = currentURL.indexOf('paid') !== -1 || currentURL.indexOf('budg') !== -1
            if (!isUrlHasTabCode) currentURL = currentURL + '/budg';
            switch (index) {
                case 0:
                    newURL = this.replaceLastOccurrence(currentURL, 'paid', routes[index]);
                    break;
                case 1:
                    newURL = this.replaceLastOccurrence(currentURL, 'budg', routes[index]);
                    break;
                default:
                    break;
            }
            // Обновление URL без перезагрузки страницы
            if (newURL !== currentURL) {
                history.pushState({}, '', newURL);
            }
        },

        replaceLastOccurrence(str, searchValue, replaceValue) {
            const lastIndex = str.lastIndexOf(searchValue);
            
            if (lastIndex === -1) {
                return str;
            }
        
            const beforeLastOccurrence = str.substring(0, lastIndex);
            const afterLastOccurrence = str.substring(lastIndex + searchValue.length);
        
            return beforeLastOccurrence + replaceValue + afterLastOccurrence;
        },

        setSelectAll(value) {
            if (this.activeTabIndex === 0) {
                this.selectAll = value;
                this.setIsAllDelete(value);
            }
            if (this.activeTabIndex === 1) {
                this.selectAllPaid = value;
                this.setIsAllDeletePaid(value);
            }
        },

        onAddCategoryClicked() {
            let budgetForm = [];
            if (this.activeTabIndex === 0) budgetForm = this.budgetForm;
            if (this.activeTabIndex === 1) budgetForm = this.budgetFormPaid;
            this.addItem(budgetForm);
        },

        onEnterCategoryName() {
            if (this.activeTabIndex === 0) this.onAddNewCat();
            if (this.activeTabIndex === 1) this.onAddNewCatPaid();
        },

        onSaveBtnClicked() {
            if (this.activeTabIndex === 0) this.prepareForSave();
            if (this.activeTabIndex === 1) this.prepareForSavePaid();
        },

        setIsAllDeletePaid(val) {
            this.budgetFormPaid.forEach(item => this.$set(item, 'itemToDelete', val));
        },

        downloadRepPaid() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            this.header.isGkkpPaid = this.isGkkpPaid;
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.getCommonText('form')} ` + this.form.code.replace('-', '_') + `${this.getCommonText('paid_services_shrt')}` + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadRepPaid()`, error.toString());
                }
            );
        },

        deleteItemWithAttachedFilesPaid(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetFormPaid.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        if (row) {
                            if (row.id > 0) {
                                await this.deleteWithAttachedFilesPaid([row], [row.id], index);
                            } else {
                                this.budgetFormPaid.splice(index, 1);
                            }
                        } else {
                            const rows = [];
                            const rowsId = [];
                            const newRowsToDelId = [];
                            this.budgetFormPaid.forEach(item => {
                                if (item.itemToDelete) {
                                    if (item.id > 0) {
                                        rows.push(item);
                                        rowsId.push(item.id);
                                    } else {
                                        newRowsToDelId.push(item.id)
                                    }
                                }
                            });
                            if (newRowsToDelId.length) {
                                this.budgetFormPaid = this.budgetFormPaid.filter(item => !newRowsToDelId.includes(item.id));
                                if (!rows.length) this.selectAllPaid = false;
                            }
                            if (rows.length) await this.deleteWithAttachedFilesPaid(rows, rowsId, index);
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        },

        async deleteWithAttachedFilesPaid(items, ids, index) {
            this.isLoad = true;
            const dataToDel = {
                items,
                form: this.form.code
            }
            
            try {
                this.calcTotalPaid(ids);
                const response = await fetch('/api-py/delete-budget-request-multiple-rows-with-files-paid/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (index !== false) {
                        this.budgetFormPaid.splice(index, 1);
                    } else {
                        this.budgetFormPaid = this.budgetFormPaid.filter(item => { return !item.itemToDelete });
                    }
                    this.makeToast('success', this.getErrText('msg'), index !== false ? this.getErrText('one_deleted') : this.getErrText('deleted'));
                } else {
                    this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            } finally {
                this.isLoad = false;
                this.selectAllPaid = false;

            }
        }, // удаление данных

        calcTotalPaid(ids) {
            this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetFormPaid, 'total', ids));
        },

        setSelectAll(value) {
            if (this.activeTabIndex === 0) {
                this.selectAll = value;
                this.setIsAllDelete(value);
            }
            if (this.activeTabIndex === 1) {
                this.selectAllPaid = value;
                this.setIsAllDeletePaid(value);
            }
        },

        getNewFilesPaid(files) {
            this.budgetFormPaid.forEach(item => {
                files.forEach((file) => {
                    const newFile = JSON.parse(JSON.stringify(file));
                    const isNotSelectedRow = file.row_id !== item.id;
                    if (isNotSelectedRow) {
                        newFile.row_id = null;
                        item['row_files'] = [...item['row_files'], newFile];
                    }
                })
            })

            this.$refs.modalFilesManagementPaid.setDataChanged(true);
            this.$refs.modalFilesManagementPaid.addNewFiles(files);
        },

        delFilePaid(fileId) {
            this.budgetFormPaid.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
        },

        triggDownloadFilePaid(filename) {
            this.$refs.fileUpdownPaid.downloadFile(filename);
        }, // инициация скачивания файла

        triggDownloadAllFilesPaid(filesList) {
            const fileNamesList = filesList.reduce((arr, item) => {
                if (typeof item === 'string') {
                    arr.push(item);
                } else {
                    arr.push(item.file_name);
                }
                return arr;
            }, []
                );
            this.$refs.fileUpdownPaid.downloadAllFileReqst(fileNamesList);
        }, // инициация скачивания прикрепленных файлов

        fileUploadPaid(rowId) {
            this.$refs.fileUpdownPaid.openModalFileUpload();
            this.$refs.fileUpdownPaid.setRowId(rowId);
        },

        toggleIsAddPaid(return_object) {
            const curBudgetForm = this.budgetFormPaid.find(b => b.id === this.rowIdPaid)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.rowIdPaid = return_object['isAdd'];
        },

        onFilesClickPaid(item) {
            this.$refs.modalFilesManagementPaid.showModal(item);
            this.rowIdPaid = item.id;
        }, // открытие модалки с файлами

        onEditNotePaid(row) {
            const modal = this.$refs.setNoteModalPaid;
            if (modal) modal.showModal(row);
        },

        onEditCatNamePaid(row) {
            const modal = this.$refs.setCatNameModalPaid;
            if (modal) modal.showModal(row);
        },

        onAddNewCatPaid() {
            const modal = this.$refs.setCatNameModalPaid;
            if (modal) modal.showModal();
        }

    },

    computed: {
        isPaidTab() {
            return this.activeTabIndex === 1;
        },

        currTabName() {
            return this.isPaidTab ? 'paid' : 'budg';
        },

        isGkkpPaid() {
            return this.paidGkkpForms.includes(this.form.code);
        },
    }
}